class Toggler {
  constructor() {
    const toggler = new Unitoggle.default({
      onOpen: tab => {
        if ( tab.id == 'header' ) {
          this.blockScroll();
        }
      },
      onClose: tab => {
        this.blockScroll('off');
      }
    });
  }

  blockScroll( status ) {
    if ( status == 'off' ) {
      document.documentElement.classList.remove('is-no-scroll');
      return;
    }

    document.documentElement.classList.add('is-no-scroll');
  }
}

export default Toggler;
